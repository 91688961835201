/* Variables */
$main-accent-color: #4286F4;
$secondary-accent-color: #373B44;
$text-shadow: 1px 1px 1px #000;
$common-margin: 20px auto 0;
$price-heading-font: 'Poppins', sans-serif;
$price-body-font: 'Lato', sans-serif;

/* General Styles / Resets */
html,
body {
	padding: 0;
	margin: 0;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	line-height: 1;
	font-family: 'Roboto', sans-serif;
	text-rendering: optimizeLegibility;
}

a,
a:link {
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	font-weight: bold;
	color: $main-accent-color;
	border-bottom: 1px solid #999;
}

/* Mixins */
@mixin mq-max($from) {
	@media screen and (max-width: $from) {
		@content;
	}
}

@mixin mq-min($from) {
	@media screen and (min-width: $from) {
		@content;
	}
}

@mixin mq-between($min, $max) {
	@media screen and (min-width: $min) and (max-width: $max) {
		@content;
	}
}

/* Helper Classes */
/* Font Styles / Modifiers */
.bold-text {
	font-weight: bold;
}

.italic-text {
	font-style: italic;
}

/* Text Formatting  */
.tab {
	text-indent: 40px
}

/* Text Color */
.blue-text {
	color: $main-accent-color;
}

.purple-text {
	color: purple;
}

.red-text {
	color: #FF0000;
}

.white-text {
	color: #FFF;
}

/* Navigation */
.header-nav,
.footer-nav {
	display: inline-block;

	a {
		color: white;
		padding: 20px;
		text-shadow: 1px 1px 1px #000;
		-webkit-transition: font-weight 0.3s,
			font-size 0.3s,
			text-shadow 0.3s;
		transition: font-weight 0.3s,
			font-size 0.3s,
			text-shadow 0.3s;

		&.active,
		&:hover {
			font-size: 20px;
			font-weight: 700;
			text-shadow: 2px 2px 2px #000;
			border-bottom: 0;
		}
	}
}

/* Header */
.header {
	padding: 40px 20px 20px;
	width: 100%;
	color: white;
	background-color: $main-accent-color;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);

	> div {
		display: inline-block;

		h1 {
			font-size: 36px;
			text-shadow: 3px 3px 3px #000;
			padding: 0 20px;

			@include mq-max(850px) {
				font-size: 30px;
			}
		}
	}

	.header-logo {
		width: 50%;
		text-align: left;

		@include mq-min(1400px) {
			display: block;
			width: 100%;
			text-align: center;
		}
	}

	.header-nav {
		width: 50%;
		text-align: right;

		@include mq-max(600px) {
			display: none !important;
		}

		@include mq-min(1400px) {
			display: block;
			width: 100%;
			text-align: center;
		}
	}

	.header-logo,
	.header-nav {
		@include mq-max(850px) {
			display: block;
			width: 100%;
			text-align: center;
		}
	}
}

/* Landing */
.landing {
	padding: 20px;
	background-color: white;
	text-align: center;
	max-width: 1400px;
	margin: 0 auto;

	@include mq-max(600px) {
		padding: 0;
	}

	.landing-promo {
		background-image: url('/images/header.jpeg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		min-height: 500px;
		color: #FFF;

		@include mq-max(600px) {
			margin: 20px 0;
		}

		h2 {
			margin: 0;
			padding: 50px 0 25px;
			text-shadow: 3px 3px 3px #000;
		}

		h3 {
			margin: 0;
			padding: 0 0 25px;
			text-shadow: 3px 3px 3px #000;
		}

		.agency-copy {
			position: relative;
			max-width: 450px;
			margin: 0 auto;
			padding: 25px;
			background-color: rgba(0, 0, 0, 0.6);
			text-shadow: 3px 3px 3px #000;
			font-weight: bold;
		}
	}

	.landing-header {
		background-color: #000;
		padding: 20px 20px 10px;
		font-family: 'Montserrat', sans-serif;

		h2,
		h3 {
			color: white;
			font-weight: bold;
			margin-block-start: 0;
			margin-block-end: 0;
		}

		h2 {
			font-size: 48px;

			@include mq-max(1000px) {
				font-size: 36px;
			}
			@include mq-max(700px) {
				font-size: 30px;
			}
			@include mq-max(600px) {
				font-size: 24px;
			}
			@include mq-max(500px) {
				font-size: 20px;
			}
		}

		hr {
			width: 80%;
			margin-block-start: 0;
			margin-block-end: 0;
			margin: 10px auto;
		}

		h3 {
			font-size: 36px;
			text-transform: uppercase;
			font-weight: bold;
			font-style: italic;
		}
	}

	.learn-more {
		display: inline-block;
		margin: 20px;
		padding: 20px;
		font-size: 30px;
		text-transform: uppercase;
		border: 1px solid $main-accent-color;
		font-weight: bold;
		font-family: 'Montserrat', sans-serif;
		color: white;
		background-color: $main-accent-color;
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
		-webkit-transition: background-color 0.5s,
			box-shadow 0.5s;
		transition: background-color 0.5s,
			box-shadow 0.5s;

		&:hover {
			background-color: #376EC8;
			box-shadow: 0 5px 18px #376EC8;
		}
	}

	.screen {
		background-color: black;
		color: #00FF00;
		font-size: 24px;
		text-align: left;
		font-style: italic;
		padding: 10px 40px 20px;
		word-break: break-word;

		code {
			font-family: Menlo;

			.blue {
				color: $main-accent-color;
			}

			.purple {
				color: purple;
			}

			.red {
				color: #FF0000;
			}

			.white {
				color: #FFF;
			}

			.indent {
				padding-left: 40px;
			}
		}
	}

	.code-legend-container {
		background-color: black;
		text-align: left;
		font-size: 24px;

		h4 {
			display: inline-block;
			color: white;
			text-decoration: underline;
			margin-block-start: 0;
			margin-block-end: 0;
			margin-left: 40px;
			padding-bottom: 20px;
		}

		.code-legend-open,
		.code-legend-close {
			vertical-align: middle;
			display: none;

			&.active {
				display: inline-block;
			}

			&:hover {
				cursor: pointer;
			}
		}

		.icon.minus-icon,
		.icon.plus-icon {
			width: 24px;
			height: 24px;
			background-color: #FFF;
			border-radius: 50%;
			margin-left: 20px;
		}

		.icon.minus-icon {
			color: #FF0000;

			&:hover {
				color: #E00000;
			}
		}

		.icon.plus-icon {
			color: #00FF00;

			&:hover {
				color: #00E000;
			}
		}

		.code-legend {
			color: #00FF00;
			padding: 0 40px 40px;
			display: none;

			p {
				margin-block-start: 0;
				margin-block-end: 0;
			}

			&.active {
				display: block;
			}
		}
	}

	.landing-clients {

		h2 {
			font-family: $price-heading-font;
		}

		.client-container {
			display: inline-block;
			vertical-align: middle;
			margin-right: 15px;

			&.serendip-logo {
				background-color: #FFBF00;
			}

			&.scrolller-logo {
				background-color: #1B252F;
	
				svg {
					padding: 0.6em;
					width: 125px;
	
					text {
						font-size: 600px;
						fill: #FFF;
						font-weight: 600;
						letter-spacing: .072em;
					}
	
					path {
						fill: #B11733;
					}
				}
			}

			&.shq-logo {
				width: 125px;
			}

			a {

				&:hover {
					color: none;
					border-bottom: none;
					cursor: pointer;
					text-decoration: none;
				}

				img {
					max-width: 125px;
				}
			}
		}
	}
}

/* Contact */
.contact-body {
	padding: 20px;
	width: 60%;
	max-width: 800px;
	margin: 0 auto;

	@include mq-max(600px) {
		width: 90%;
	}

	h2 {
		text-decoration: underline;
	}

	.form-fields-required {
		font-size: 14px;
	}

	input[type=text],
	input[type=number],
	input[type=email],
	textarea {
		width: 100%;
		padding: 12px;
		border: 1px solid #ccc;
		border-radius: 4px;
		box-sizing: border-box;
		margin-top: 6px;
		margin-bottom: 16px;
		resize: vertical;
	}

	input[type=text].touched:valid,
	input[type=number].touched:valid,
	input[type=email].touched:valid,
	textarea.touched:valid {
		outline: #28A745 auto 5px;
	}

	input[type=text].touched:invalid,
	input[type=number].touched:invalid,
	input[type=email].touched:invalid,
	textarea.touched:invalid {
		outline: #DC3545 auto 5px;
	}

	input[type=submit] {
		background-color: $main-accent-color;
		color: white;
		padding: 12px 20px;
		border: none;
		border-radius: 4px;
		cursor: pointer;
	}

	input[type=submit]:hover {
		background-color: #376EC8;
		box-shadow: 0 5px 18px #376EC8;
	}

	.input-container,
	.textarea-container {
		position: relative;
	}

	.input-icon,
	.textarea-icon {
		position: absolute;
		left: -45px;
		top: 30px;
		z-index: 1;
		font-size: 2em;
	}

	.input-icon svg,
	.textarea-icon svg {
		color: $main-accent-color;
	}

	.form-label {
		display: block;
		margin: 10px 0 0;
	}

	.required-symbol {
		color: #E80000;
	}

	.contacted {
		margin-top: 1rem;

		.thank-you {
			font-size: 1.5rem;
		}
	}
}

/* Services */
.services-body {
	max-width: 1400px;
	margin: 0 auto;
	padding: 20px 40px;

	h2,
	h3 {
		text-align: center;
		text-decoration: underline;
	}

	p {
		font-size: 1.25rem;
	}
}

/* Footer */
.footer {
	color: white;
	background-color: $main-accent-color;
	border-top: 1px solid rgba(0, 0, 0, 0.1);

	.footer-info {
		width: 100%;
		border-bottom: 1px solid white;

		.footer-logo,
		.company-contact {
			display: inline-block;
			text-align: center;

			@include mq-max(600px) {
				display: block;
			}
		}

		.footer-logo {
			width: 30%;
			padding: 40px 20px;
			border-right: 1px solid white;

				@include mq-max(600px) {
					width: 100%;
					padding: 20px;
					border: 0;
				}

			h2 {
				margin-block-start: 0;
				margin-block-end: 0;
				text-shadow: 3px 3px 3px #000;
			}
		}

		.company-contact {
			width: 70%;

			@include mq-max(600px) {
				width: 100%;
				padding-bottom: 20px;
			}

			.icon {
				color: white;
				font-size: 20px;
				vertical-align: bottom;
				padding-right: 5px;
				vertical-align: middle;
			}

			a {
				color: white;
				text-shadow: 2px 2px 2px #000;
			}
		}
	}

	.footer-nav {
		padding-top: 20px;
		display: block;
		text-align: center;

			@include mq-max(600px) {
				width: 100%;
				margin: 0 auto;
			}
		a {
			@include mq-max(600px) {
				padding: 10px;
				font-size: 14px;

				&.active,
				&:hover {
					font-size: 20px;
				}
			}
		}
	}

	.copyright-text {
		padding: 20px 0;
		text-align: center;
		font-weight: bold;
		text-shadow: 1px 1px 1px #000;
		margin-block-start: 0;
		margin-block-end: 0;
	}
}

/* Price Comparison */
.price-comparison-container {
	margin: 0 0 50px;
	background-color: #F8F9FB;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: $price-body-font;

	h3 {
		font-size: 1.5rem;

		@include mq-max(900px) {
			padding-bottom: 25px;
		}
	}

	.price-comparison {
		display: flex;
		justify-content: center;
		align-items: stretch;
		width: 100%;
		margin: 1rem;

		@include mq-max(900px) {
			flex-direction: column;
			align-items: center;
			width: 80%;
			margin: 0 auto;
		}
	}

	.price-column {
		background-color: white;
		box-shadow: 0 7px 30px rgba(52, 31, 97, 0.1);
		padding: 2rem;
		flex-grow: 1;
		flex-basis: 0;
		max-width: 275px;
		border-radius: 8px;

		@include mq-max(900px) {
			max-width: 90%;
			width: 100%;
			padding: 2.5rem 1rem;
		}

		&.popular {
			position: relative;
			background-color: $main-accent-color;
			color: white;
			box-shadow: 0 7px 30px rgba(52, 13, 135, 0.3);
			margin: -1.5rem 0;
			// add difference between regular columns to center text
			padding: 3.5rem 2rem;

			@include mq-max(900px) {
				box-shadow: none;
				padding: 3rem 1rem;
			}

			.most-popular {
				position: absolute;
				top: 1rem;
				left: 1rem;
				right: 1rem;
				text-align: center;
				text-transform: uppercase;
				font-size: 1.5rem;
				font-weight: bold;
				font-family: $price-heading-font;
			}

			.divider {
				background-color: rgba(255, 255, 255, 0.3);
			}

			.pricing-cta {
				background-color: white;
				color: $main-accent-color;
			}

			.pricing-cta:hover,
			.pricing-cta:focus {
				background-color: white;
				box-shadow: 0 5px 18px white;
				transform: scale(1.1);

				@include mq-max(900px) {
					transform: scale(1);
				}
			}
		}

		&:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

			&.popular {
				border-radius: 8px;
			}
		}

		&:nth-child(2){
			border-radius: 0;

			&.popular {
				border-radius: 8px;
			}
		}

		&:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;

			@include mq-max(900px) {
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}

			&.popular {
				border-radius: 8px;
			}
		}

		.price-header {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-family: $price-heading-font;
			font-weight: bold;

			.price {
				font-size: 3.5rem;
				display: flex;

				.dollar-sign {
					font-size: 2rem;
					margin-top: .5rem;
					margin-right: .25rem;
				}

				.per-hour {
					font-size: 1.5rem;
					align-self: flex-end;
					margin-bottom: 0.25rem;
					text-transform: uppercase;
				}
			}

			.plan-name {
				text-transform: uppercase;
				font-size: 1.75rem;
				margin-top: 1rem;
				margin-bottom: 0;
			}
		}

		.divider {
			height: 1px;
			width: 100%;
			margin: 2rem auto;
			background-color: rgba(0, 0, 0, 0.3);

			@include mq-max(900px) {
				margin: 1rem auto;
			}
		}

		.feature-list {

			.feature {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin: .5rem;
				font-weight: bold;

				@include mq-max(900px) {
					font-size: 1.5rem;
					width: 100%;
					margin: 1rem auto;
				}

				&.inactive {
					color: #DC3545;
					text-decoration: line-through;
					text-decoration-thickness: 3px;
				}

				.feature-inactive-inner {
					color: #999999;
				}

				.icon {
					background-color: white;
					border-radius: 50%;
				}

				.icon.checked-circle {
					height: 2em;
					width: 2em;
					margin-right: 0.5rem;
					color: #28A745;
				}

				.icon.close-circle {
					height: 2em;
					width: 2em;
					margin-right: 0.5rem;
					color: #DC3545;
				}
			}
		}

		.pricing-cta {
			border: none;
			background-color: $main-accent-color;
			color: white;
			width: 100%;
			padding: .75rem 1rem;
			cursor: pointer;
			font-family: $price-heading-font;
			font-weight: bold;
			border-radius: 4px;
			box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
			margin-top: 3rem;
			transform: scale(1);
			-webkit-transition: all 0.7s;
			transition: all 0.1s;
		}

		.pricing-cta:hover,
		.pricing-cta:focus {
			background-color: #376EC8;
			box-shadow: 0 5px 18px #376EC8;
			transform: scale(1.1);

			@include mq-max(900px) {
				transform: scale(1);
			}
		}
	}
}

/* Work */
.projects-body {
	max-width: 1400px;
	margin: 0 auto;
	padding: 0 20px;
	text-align: center;

	h2 {
		text-decoration: underline;
		text-align: center;
		margin: 20px auto 0;
	}

	.client-container {
		display: inline-block;
		vertical-align: middle;
		margin-right: 25px;
		margin-top: 25px;

		@include mq-max(800px) {
			margin-right: 0px;

			&:nth-child(even){
				margin-right: 20px;
			}
		}

		a:hover {
			text-decoration: none;
			border-bottom: none;
		}

		img {
			max-width: 300px;
			max-height: 300px;
		}

		&.klein-firm,
		&.shq-logo {
			img {
				width: 300px;
			}
		}

		&.serendip-logo {
			background-color: #FFBF00;
		}

		&.scrolller-logo {
			background-color: #1B252F;

			svg {
				padding: 1.5em;
				width: 300px;

				text {
					font-size: 600px;
					fill: #FFF;
					font-weight: 600;
					letter-spacing: .072em;
				}

				path {
					fill: #B11733;
				}
			}
		}

		.extra-info {
			text-align: center;
			font-weight: bold;
			font-size: 20px;
		}
	}

	.work-example-container {
		display: inline-block;
		vertical-align: top;
		margin-right: 25px;
		margin-top: 20px;

		@include mq-max(800px) {
			margin-right: 0px;
			max-width: 325px;

			&:nth-child(odd){
				margin-right: 20px;
			}
		}

		img {
			width: 600px;
			max-width: 100%;
		}

		a:hover {
			text-decoration: none;
			border-bottom: none;
		}

		&:last-of-type {
			margin-bottom: 20px;
		}
	}
}
